import api from '@/services/api/api';

export const useSummerArticles = () => {
  const summerArticles = useInfiniteQuery(
    ['summerArticles'],
    async () => await api.articles.getSummerArticles(),
    {
      staleTime: Infinity,
      select: ({ pages }) => pages.flatMap(({ data }) => data?.data),
      refetchOnMount: true,
    },
  );

  summerArticles.suspense();

  return {
    summerArticles,
  };
};
